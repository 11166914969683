.our-offering {
    height: auto;
    padding: 0px 20px;
}

.container {
    padding: 60px 80px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    min-height: 100vh;
}

.our-offering-headline {
    font-size: 28px;
    padding: 0px 40px;
    text-align: center;
    font-weight: 700;
    margin-top: 80px;
}

.our-offering-headling-two {
    margin-top: 10px;
    text-align: center;
}

/* Section styling */
.section {
    text-align: center;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    cursor: pointer;
    transform: scale(0.9);
}

.section:hover {
    transform: scale(1.0);
}

/* Section images */
.section img {
    width: 100%;
    height: 250px;
    border-radius: 8px;
}

/* Section headings */
.section h2 {
    font-size: 28px;
    font-weight: 700;
    margin-top: 20px;
    color: #333;
}

/* Section paragraphs */
.section p {
    font-size: 18px;
    color: #666;
    margin: 10px 0;
}

/* Section lists */
.section ul {
    list-style-type: disc;
    padding-left: 20px;
    text-align: left;
}

.section ul li {
    font-size: 16px;
    color: #666;
    margin: 5px 0;
}

/* Responsive styling */
@media (max-width: 768px) {
    .container {
        padding: 40px 10px;
        grid-template-columns: 1fr; /* Single column layout on small screens */
    }

    .section {
        padding: 15px;
    }

    .section h2 {
        font-size: 24px;
    }

    .section p, .section ul li {
        font-size: 16px;
    }

    .our-offering-headline {
        font-size: 24px;
    }
    
    .our-offering-headling-two {
        text-align: center;
        padding: 0 40px;
        font-size: 16px;
    }
}
