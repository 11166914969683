@media screen and (max-width: 550px) {
    .heading-one {
        font-size: 24px;
        letter-spacing: 20px;
        transform: scale(1.0);
    }

    .heading-two {
        font-size: 18px;
        padding: 10px 20px;
    }

    .heading-three {
        font-size: 14px;
    }

    .drone-image {
        height: 150px;
    }

    .headings-container {
        padding-bottom: 20%;
    }

    .landing-buttons-container {
        padding: 0px 20px;
    }

    .know-more-button {
        padding: 12px 20px;
    }
    
    .get-in-touch-button-home {
        padding: 12px 40px;
        backdrop-filter: blur(40px);
    }
}