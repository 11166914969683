@media screen and (max-width: 830px) {
    .header-options li {
        margin-left: 10px;
    }
}

/* Mobile Styles */
@media (max-width: 768px) {

    .header-section {
        padding: 10px 24px;
    }

    .options-container {
        display: flex;
    }

    .header-logo {
        height: 20px;
        display: none;
    }

    .header-title {
        font-size: 18px;
        margin-left: 8px;
        text-align: left;
    }
    
    .header-options {
        display: none;
    }

    .header-button-container {
        display: none;
    }

    .hamburger-menu {
        display: block;
        cursor: pointer;
    }

    .hamburger-menu div {
        width: 25px;
        height: 3px;
        background-color: #000;
        margin: 5px 0;
    }

    .header-section {
        flex-direction: row;
        align-items: center;
    }
}

/* Tablet and smaller devices */
@media (max-width: 992px) and (min-width: 769px) {

    .header-title {
        margin-left: 0px;
    }

    .header-logo {
        display: none;
    }

    .header-options ul {
        display: flex;
        padding: 0;
        margin: 0;
    }

    .header-options li {
        margin-left: 10px;
    }
}