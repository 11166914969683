.mobile-menu-container {
    z-index: 1000;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    margin-top: 70px;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(40px);
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: first baseline;
    background-color: rgba(222, 222, 222, 0.102);
}

.menu-option-list {
    padding: 20px;
    list-style: none;
    font-family: "Poppins", sans-serif;
    text-align: center;
}

.menu-option-list li {
    padding: 10px 0px;
    height: auto;
    color: #000000;
    margin-top: 20px;
}

.menu-option-list li a {
    color: #000000;
    text-decoration: none;
}
