.header-section {
    display: flex;
    padding: 8px 40px;
    justify-content: space-between;
    align-items: center; 
    top: 0;
    left: 0;
    width: 100%;
    position: fixed;
    z-index: 1000;
    background-color: #ffffff61;
    backdrop-filter: blur(40px);
    height: auto;
    -webkit-animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.header-logo-title {
    display: flex;
    align-items: center;
    -webkit-animation: slide-in-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.header-logo-title a {
    text-decoration: none;
    align-items: center;
    display: flex;
    color: #000000;
}

.header-logo {
    height: 60px;
}

.header-title {
    margin-left: 20px;
    padding: 10px 0;
    text-align: center;
}

.header-button-container {
    padding: 10px;
    -webkit-animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.get-in-touch-button {
    padding: 10px 20px;
    border-radius: 10px;
    border: none;
    color: #FFFFFF;
    font-size: 12px;
    font-weight: 600;
    cursor: pointer;
    background-color: var(--primaryColor);
}

.get-in-touch-button:hover {
    background-color: var(--primaryColor);
}

.header-options {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 40px;
}

.header-options ul {
    list-style: none;
    display: flex;
    padding: 0;
    margin: 0;
}

.header-options li {
    margin-left: 40px;
    position: relative;
}

.header-options li a {
    color: #000000;
    text-align: center;
    text-decoration: none;
    font-size: 14px;
    padding: 5px 10px;
    border-radius: 5px;
    transition: color 0.3s, background-color 0.3s;
}

.header-options li a:hover {
    color: var(--primaryColor);
}

.header-options li a.active-link {
    color: var(--primaryColor);
    font-weight: 600;
}

.header-options li a.active-link::after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%);
    width: 20px;
    height: 3px;
    background-color: var(--primaryColor);
    border-radius: 3px;
}

.options-container {
    display: none;
}

.show-menu-icon {
    height: 22px;
}