/* Device level 3 */
@media screen and (max-width: 1280px) {

    .blog-section-highlight-container_h2 {
        padding: 0 40px;
    }
    
    .top-blogs-post-container {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 40px;
    }
}


/* Device level 2 */
@media screen and (max-width: 780px) {

    .blog-section-highlight-container_h2 {
        padding: 0 40px;
    }
    
    .top-blogs-post-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 40px;
    }
}


/* Device level 1 */
@media screen and (max-width: 580px){
    .blog-section-highlight-container_h2 {
        padding: 0 40px;
    }

    .blog-posts-container_top-blogs-container {
        padding: 20px 30px;
    }

    .top-blogs-post-container {
        padding: 20px 0px;
        display: block;
        grid-template-columns: repeat(4, 1fr);
        gap: 40px;
        width: auto;
    }
    
    .blog-post-card {
        margin-bottom: 10px;
    }
}