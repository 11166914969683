.landing-page {
    height: 100vh;
    width: 100%;
    position: relative;
    overflow: hidden;
}

.background-container {
    height: 100%;
    width: 100%;
}

.background-image {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.landing-page-container {
    z-index: 2;
    height: 100%;
    width: 10   0%;
    backdrop-filter: blur(20px);
    transform: translate(0px, -100%);
}

.headings-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 100%;
    z-index: 4;
    padding-bottom: 40px;
    height: auto;

}

.heading-one {
    font-size: 38px;
    font-weight: 800;
    letter-spacing: 40px;
    color: #FFFFFF;
    text-align: center;
    transform: scale(0.8);
    -webkit-animation: slide-in-elliptic-left-fwd 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	    animation: slide-in-elliptic-left-fwd 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.heading-two {
    font-size: 24px;
    font-weight: 500;
    color: #FFFFFF;
    text-align: center;
    margin-top: 20px;
    -webkit-animation: slide-in-blurred-bottom 0.6s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
	        animation: slide-in-blurred-bottom 0.6s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
}

.heading-three {
    font-size: 16px;
    font-weight: 500;
    color: #FFFFFF;
    padding: 0 40px;
    text-align: center;
    margin-top: 10px;
    -webkit-animation: slide-in-blurred-bottom 0.6s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
	        animation: slide-in-blurred-bottom 0.6s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
}

.drone-image {
    height: 240px;
}

.landing-buttons-container {
    margin-top: 40px;
    -webkit-animation: slide-in-blurred-bottom 0.6s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
	        animation: slide-in-blurred-bottom 0.6s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
}

.know-more-button {
    padding: 12px 40px;
    cursor: pointer;
    background-color: var(--primaryColor);
    color: #ffffff;
    border: 0 solid;
    font-weight: 400;
    border-radius: 8px;
}

.know-more-button a {
    text-decoration: none;
    color: white;
}

.get-in-touch-button-home {
    padding: 12px 40px;
    cursor: pointer;
    background-color: #00ac6d00;
    color: #ffffff;
    border: #FFFFFF solid 2px;
    font-weight: 500;
    border-radius: 8px;
    margin-left: 20px;
}

.get-in-touch-button-home:hover {
    background-color: #00000041;
    color: #ffffff;
}
