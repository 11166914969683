.drone-overview {
    max-width: 800px;
    margin: auto;
    padding: 20px;
    font-family: Arial, sans-serif;
}

.drone-overview h1 {
    font-size: 20px;
    margin-bottom: 20px;
}

.drone-overview h2 {
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.drone-overview h3 {
    font-size: 18px;
    margin-top: 15px;
}

.drone-overview ul {
    list-style-type: disc;
    margin-left: 20px;
}

.drone-overview li {
    margin-bottom: 10px;
}

.drone-overview p {
    line-height: 1.6;
}
