.copyright-holder {
    padding: 40px 40px;
    text-align: center;
    font-size: 14px;
}

@media screen and (max-width: 560px) {
    .copyright-holder {
        padding: 10px 40px;
        font-size: 12px;
    }
}