.reconginazation-section {
    padding: 10px 80px;
    text-align: center;
    box-sizing: border-box;
}

.reconginazation-section_primary-heading {
    margin-top: 80px;
    text-align: center;
    font-size: 48px;
    font-weight: 700;
}

.reconginazation-section_content-heading {
    text-align: center;
    font-size: 18px;
    margin-top: 10px;
}

.reconginazation-section img {
    height: 440px;
    margin-top: 40px;
}

.reconginazation-section p {
    padding: 0px 20%;
    margin-top: 40px;
}

@media (max-width: 768px) {

    .reconginazation-section_primary-heading {
        font-size: 28px;
    }

    .reconginazation-section_content-heading {
        font-size: 16px;
    }
    .reconginazation-section {
        padding: 10px 20px;
    }

    .reconginazation-section img {
        height: 280px;
    }

    .reconginazation-section p {
        padding: 0px 20px;
    }
}