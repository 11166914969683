.blog-section {
    height: 100%;
}

.blog-section a {
    text-decoration: none;
    color: black;
}

.blog-section-highlight-container {
    padding-top: 80px;
    padding-bottom: 40px;
}

.blog-section-highlight-container_h1 {
    font-size: 48px;
    text-align: center;
    font-weight: 800;
}

.blog-section-highlight-container_h2 {
    font-size: 18px;
    text-align: center;
    font-weight: 400;
    padding: 0 350px;
    margin-top: 10px;
}

.blog-posts-container_top-blogs-container {
    padding: 20px 40px;
}

.top-blogs-category-title {
    margin-top: 20px;
    font-size: 18px;
    font-weight: 600;
}

.top-blogs-post-container {
    padding: 20px 0px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 40px;
}

.blog-post-card {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-radius: 8px;
    overflow: hidden;
    cursor: pointer;
    transform: scale(0.98);
}

.blog-post-card:hover {
    transform: scale(1.0);
}

.blog-post-card-image {
    width: 100%;
}

.blog-post-card-title {
    font-size: 22px;
    font-weight: 600;
    padding: 24px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.blog-post-card-title_image {
    height: 14px;
    margin-left: 10px;
}

.blog-post-card-information-container {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    display: inline-block;
}

.blog-post-card-profile-information {
    padding-right: 10px;
}

.blog-post-card-information-container-main {
    display: flex;
    background-color: var(--primaryColorLightBackground);
    border-radius: 100px;
    padding: 10px 10px;
    justify-content: center;
    align-items: center;
}

.blog-post-card-profile-image {
    height: 40px;
    border-radius: 100%;
    margin-right: 10px;
}

.blog-post-card-prfile-name {
    font-weight: 600;
    font-size: 14px;
}

.blog-post-card-prfile-article-date {
    font-size: 14px;
}


.blog-post-card_description {
    font-size: 16px;
}

.blog-post-card_description {
    padding: 0px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
}

.blog-post-card-read-more-button {
    font-weight: 600;
    font-size: 16px;
    margin-left: 10px;
    color: var(--primaryColor);
}

.blog-post-card-read-more-button:hover {
    color: var(--primaryColorHover);
}

.read-blog-button-container {
    padding: 0 20px;
    margin-bottom: 20px;
}