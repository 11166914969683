@media screen and (max-width: 560px) {
    .contact-us-section {
        padding: 0px 40px;
    }

    .contact-us-heading-container {
        font-size: 24px;
    }

    .contact-us-heading-container-two {
        font-size: 14px;
    }

    .about-us-content {
        padding: 40px 0px;
    }

    .about-us-image {
        height: 180px;
    }

    .about-us-image:first-child {
        display: block;
    }

    .about-us-content p {
        padding: 0px 10px;
        margin-top: 40px;
    }

    .two-m {
        font-size: 18px;
    }

    .call-us-button {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}