.about-section {
    padding: 0px 10%;
    height: auto;
    font-family: Arial, sans-serif;
}

.about-heading-container {
    margin-top: 140px;
    text-align: center;
    font-size: 48px;
    font-weight: bold;
    color: #333;
}

.about-heading-container-two {
    text-align: center;
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 20px;
    color: #555;
}

.about-us-content {
    text-align: center;
    padding: 40px 0;
}

.about-us-image {
    width: 100%;
    max-height: 400px;
    object-fit: cover;
    border-radius: 10px;
    margin-bottom: 20px;
}

.contact-us-button-container {
    text-align: center;
    margin: 20px 0;
}

.contact-us-button {
    padding: 10px 30px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: transform 0.2s ease;
}

.contact-us-button:hover {
    transform: scale(1.05);
    background-color: #0056b3;
}

.read-more-span {
    color: #007bff;
    cursor: pointer;
    margin-left: 5px;
    text-decoration: underline;
    transition: color 0.3s ease;
}

.read-more-span:hover {
    color: #0056b3;
}

.extra-paragraph {
    display: block;
    margin-top: 10px;
    color: #444;
    text-align: justify;
    transition: max-height 0.5s ease-in-out;
}
