@media screen and (max-width: 560px) {
    .about-section {
        padding: 0px 40px;
    }

    .about-heading-container {
        font-size: 28px;
    }

    .about-heading-container-two {
        font-size: 18px;
    }

    .about-us-content {
        padding: 40px 0px;
    }

    .about-us-image {
        height: 180px;
    }

    .about-us-image:first-child {
        display: block;
    }

    .about-us-content p {
        padding: 0px 10px;
        margin-top: 40px;
    }

    .two-m {
        font-size: 18px;
    }

    .contact-us-button {
        display: block;
    }

}