/* Basic styling for article page */
.article-page {
    max-width: 800px;
    margin: auto;
    padding: 20px;
}

.article-page-thumbnail {
    width: 100%;
    height: auto;
}

.article-page-title {
    font-size: 2rem;
    margin-top: 10px;
}

.article-page-date {
    font-size: 1rem;
    color: gray;
}

.article-page-author-image {
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.article-page-author-info {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.article-page-author-name {
    margin-left: 10px;
    font-weight: bold;
}

.article-page-content {
    margin-top: 20px;
    line-height: 1.6;
}

.article-profile {
    display: flex;
    margin-top: 20px;
    align-items: center;
}