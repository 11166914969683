/* Overall section padding and height */
.contact-us-section {
    padding: 60px 20px;
    height: auto;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* Main heading styling */
.contact-us-heading-container {
    margin-top: 60px;
    text-align: center;
    font-size: 36px;
    font-weight: 700;
    padding: 0 20px;
}

/* Subheading styling */
.contact-us-heading-container-two {
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 20px;
    padding: 0 20px;
}

/* Contact content styling */
.contact-us-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

/* Location container styling */
.location-container {
    padding: 20px;
    display: block;
    align-items: center;
    text-align: center;
    justify-content: center;
}

.location-container:nth-child(2) {
    display: flex;
    margin: 0 20px;
}

.location-container img {
    height: 14px;
    margin-right: 10px;
}

.location-container address {
    margin-left: 0;
    font-style: normal;
    font-size: 16px;
}

.location-container a {
    text-decoration: none;
    color: #00AC6E;
    font-size: 16px;
}

/* Button container styling */
.contact-us-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
}

/* Button styling */
.call-us-button {
    padding: 14px 20px;
    cursor: pointer;
    background-color: var(--primaryColor);
    color: #ffffff;
    border: 0;
    font-weight: 600;
    border-radius: 50px;
    width: 200px;
    font-size: 14px;
    text-align: center;
    margin: 10px;
}

.call-us-button img {
    margin-left: 10px;
}

/* Form styling */
.leave-a-message-form {
    box-sizing: border-box;
    width: 100%;
    max-width: 600px;
    margin: 20px auto;
    padding: 20px 20px;
    border: 1px solid #dcdcdc;
    border-radius: 8px;
    background-color: #f9f9f9;
}

/* Input and textarea styling */
.leave-a-message-form > div {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.user-inputs {
    padding: 14px 16px;
    border-radius: 6px;
    border: 1px solid #dcdcdc;
    flex: 1;
}

.first-last-name {
    display: flex;
    gap: 20px;
}

.user-inputs:first-of-type {
    flex: 1;
}

.user-inputs:nth-of-type(2) {
    flex: 1;
}

.single-userinput {
    width: 100%;
    margin-top: 20px;
}

textarea {
    resize: vertical;
    height: 100px;
}

/* Submit button styling */
.submit-button {
    padding: 12px 40px;
    cursor: pointer;
    background-color: var(--primaryColor);
    color: #ffffff;
    border: 0;
    font-weight: 500;
    border-radius: 8px;
    width: 100%;
    margin-top: 20px;
}

.contact-social-media {
    margin-bottom: 20px;
    margin-top: 20px;
}

.contact-social-media img {
    height: 24px;
    margin-left: 10px;
    margin-right: 10px;
}

@media (max-width: 768px) {
    .contact-us-section {
        padding: 40px 10px;
    }

    .contact-us-heading-container {
        font-size: 28px;
        padding: 0 10px;
    }

    .contact-us-heading-container-two {
        font-size: 18px;
        padding: 0 10px;
    }

    .location-container {
        padding: 10px 0px;
    }

    .call-us-button {
        width: 100%;
        padding: 14px 20px;
    }

    .leave-a-message-form {
        padding: 10px;
    }

    .user-inputs {
        width: 100%;
        margin-bottom: 10px;
    }

    .first-last-name {
        flex-direction: column;
        gap: 15px;
    }

    .leave-a-message-form {
        width: 100%;
        padding: 20px 24px;
    }
}
