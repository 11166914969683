/* Product Grid Styling */
.product-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
}

.product-card {
    width: 250px;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    text-align: center;
    transition: transform 0.3s;
}

.product-card:hover {
    transform: scale(1.05);
}

.product-image {
    width: 100%;
    height: auto;
    border-radius: 8px;
    margin-bottom: 10px;
}

/* Modal Styling */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    overflow: auto;
}

.modal-content {
    background: #fff;
    border-radius: 12px;
    width: 90%;
    max-width: 1200px;
    height: 90%; /* Full-height modal */
    overflow-y: auto;
    padding: 30px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
    position: relative;
    text-align: center;
}

.modal-content.modal-fullsize {
    width: 100%; /* Fullscreen width */
    height: 100%; /* Fullscreen height */
    border-radius: 0; /* No border for fullscreen */
}

.modal-body img {
    width: 50%;
    max-height: 300px;
    object-fit: contain;
    margin-bottom: 20px;
}

.modal-body h1 {
    font-size: 28px;
    color: #333;
    margin-bottom: 15px;
}

.modal-body p {
    font-size: 18px;
    line-height: 1.8;
    margin-bottom: 10px;
    color: #666;
}

.modal-body ul {
    list-style: disc;
    padding: 0 20px;
    text-align: left;
}

/* WhatsApp Button Styling */
.whatsapp-button {
    display: inline-block;
    background-color: #25d366;
    color: white;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 8px;
    text-decoration: none;
    margin-top: 20px;
    transition: background-color 0.3s ease;
}

.whatsapp-button:hover {
    background-color: #128c7e;
}

/* Close button styling */
.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 30px;
    color: #333;
    cursor: pointer;
}

@media (max-width: 768px) {
    .modal-content {
        width: 95%;
        height: 90%;
    }

    .modal-body img {
        width: 80%;
    }
}
.upi-payment-button {
    background-color: #4CAF50; /* Green button */
    color: white;
    font-size: 18px;
    padding: 15px 30px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
    transition: background-color 0.3s;
}

.upi-payment-button:hover {
    background-color: #45a049; /* Darker green on hover */
}
