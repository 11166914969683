.team-overview-section {
    width: 100%;
    padding: 20px 40px;
    box-sizing: border-box;
    height: auto;
}

.team-overview-heading {
    font-weight: 700;
    font-size: 48px;
    padding-left: 10px;
    margin-top: 40px;
    text-align: center;
}

.two {
    font-size: 18px;
    font-weight: 400;
    margin-top: 0px;
    text-align: center;
}

.team-members-profiles-container_bg {
    display: flex;
    align-items: center;
    justify-content: center;
}

.team-members-profiles-container {
    padding: 40px 20px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 0px;
    margin-top: 20px;
    align-items: center;
    width: auto;
    justify-content: center;
}

.team-members-profiles-container-two {
    padding: 40px 20px;
    display: flex;
    margin-top: 20px;
    align-items: center;
    justify-content: center;
    padding: 40px 20px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 0px;
    margin-top: 20px;
    align-items: center;
    width: auto;
    justify-content: center;
}

.team-member-profile {
    border-radius: 10px;
    overflow: hidden;
    max-width: 300px;
    background-color: #f7fffc;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    display: block;
    justify-content: space-between;
    cursor: pointer;
    height: auto;
    transform: scale(0.8);
}

.team-member-profile:hover {
    transform: scale(0.9);
}

.member-profile-image {
    width: 100%;
    height: 300px;
    aspect-ratio: 5 / 4;
    object-fit: cover;
    object-position: 0px 0px;
}

.member-details-container {
    padding: 20px 20px;
}

.member-name {
    font-size: 18px;
    font-weight: 600;
    text-align: left;
}

.member-role {
    text-align: left;
    overflow: hidden;
}

.member-social-media {
    padding-left: 20px;
    padding-bottom: 20px;
    display: flex;
    align-items: center;
}

.linkedin-icon {
    height: 24px;
}

.instagram-icon {
    height: 24px;
    margin-left: 14px;
}
.team-overview-section {
    width: 100%;
    padding: 40px 20px;
    box-sizing: border-box;
    background-color: #f9f9f9;
    height: auto;
}

.team-overview-heading {
    font-weight: 700;
    font-size: 48px;
    margin-bottom: 20px;
    text-align: center;
}

.two {
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 40px;
    text-align: center;
    color: #555;
}

.team-members-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    justify-items: center;
    padding: 20px;
}

.contact-card {
    background-color: white;
    border-radius: 12px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    overflow: hidden;
    max-width: 300px;
    width: 100%;
    text-align: center;
    transform: scale(1);
    transition: transform 0.3s, box-shadow 0.3s;
    cursor: pointer;
}

.contact-card:hover {
    transform: scale(1.05);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 8px 16px;
}

.card-content {
    padding: 20px;
}

.member-name {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 8px;
    color: #333;
}

.member-role {
    font-size: 16px;
    font-weight: 400;
    color: #777;
    margin-bottom: 16px;
}

.contact-buttons {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 16px;
}

.contact-button {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #eaeaea;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s, transform 0.3s;
}

.contact-button:hover {
    background-color: #ddd;
    transform: scale(1.1);
}

.contact-icon {
    font-size: 18px;
    color: #555;
}
