@media screen and (max-width: 1020px) {
    .team-members-profiles-container {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media screen and (max-width: 820px) {
    .team-members-profiles-container {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 560px) {

    .team-overview-section {
        padding: 0px 10px;
    }
    
    .team-members-profiles-container {
        display: block;
        width: 100%;
    }

    .team-overview-heading {
        font-size: 28px;
        text-align: center;
        margin-top: 120px;
    }

    .one {
        margin-top: 20px;
        text-align: center;
    }

    .two {
        font-size: 14px;
        margin-top: 0px;
    }

    .team-members-profiles-container {
        padding: 10px 10px;
    }

    .team-member-profile {
        width: 100%;
        max-width: 100%;
        margin-top: 0px;
        transform: scale(0.9);
    }

    .member-profile-image {
        height: 250px;
        width: 100%;
    }

    .team-members-profiles-container-two {
        width: 100%;
        display: block;
    }

    .team-overview-heading:nth-child(2) {
        margin-top: 10px;
    }
    
}
